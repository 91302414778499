import React from "react";
import Link from 'gatsby-link'
import {home, our_services, portfolio,about_us, blog, contact_us, careers} from '../strings'

function Languages(prefix_string_page){
    if(prefix_string_page === "https://www.wellpromotion.ba"){
        return(
        <li className="languages"><a href="https://www.wellpromotion.ba" className="arrow">English</a>
            <ul>                 
                <li className=""><a href="https://www.wellpromotion.ba/bs">Bosnian</a></li>
            </ul>
        </li>
        )   
    }
    else if(prefix_string_page === "https://www.wellpromotion.ba/bs"){
        return(
            <li className="languages"><a href="https://www.wellpromotion.ba/bs" className="arrow">Bosanski</a>
                <ul>                 
                    <li className=""><a href="https://www.wellpromotion.ba">Engleski</a></li>
                </ul>
            </li>
        )        
    }
    return(
        null
    )
}

function LanguagesMob(prefix_string_page){
    if(prefix_string_page === "https://www.wellpromotion.ba"){
        return(
            <div className="languages-mobile">
                <a href="https://www.wellpromotion.ba" className="selected">EN</a>
                <a href="https://www.wellpromotion.ba/bs">BS</a>
            </div>
        )
    }
    else if(prefix_string_page === "https://www.wellpromotion.ba/bs"){
        return(
            <div className="languages-mobile">
                <a href="https://www.wellpromotion.ba">EN</a>
                <a href="https://www.wellpromotion.ba/bs" className="selected">BS</a>
            </div>
        )
    }
    return(
        null
    )
}


class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = { visible: false };
    }
    show() {
        this.setState({ visible: true });
    }
    hide() {
        this.setState({ visible: false });
    }

    render() {
        var prefix_string_page = "https://www.wellpromotion.ba";
        var link_consulting = "https://www.wellpromotion.ba/consulting";
        var link_our_services = "https://www.wellpromotion.ba/#our-services";
        var link_portfolio = "https://www.wellpromotion.ba/portfolio/web-design";
        var link_blog = "https://www.wellpromotion.ba/blog";
        var link_contact = "https://www.wellpromotion.ba/contact";
        var link_about_us = "https://www.wellpromotion.ba/about-us";
        var link_careers = "https://www.wellpromotion.ba/well-careers";
        var contact_tag = "Arrange a free consultation and find out more about us and our IT (programming web sites & apps) and marketing services (SEO, Google Ads & social media advertising)";
        if(this.props.prefix_string_page === "bs"){
            prefix_string_page = "https://www.wellpromotion.ba/bs";
            link_consulting = "https://www.wellpromotion.ba/bs/konzalting";
            link_our_services = "https://www.wellpromotion.ba/bs/#nase-usluge";
            link_portfolio = "https://www.wellpromotion.ba/bs/portfolio/web-dizajn";
            link_blog = "https://www.wellpromotion.ba/bs/blog";
            link_contact = "https://www.wellpromotion.ba/bs/kontakt";
            link_about_us = "https://www.wellpromotion.ba/bs/o-nama";
            link_careers = "https://www.wellpromotion.ba/bs/well-karijera";
            contact_tag = "Dogovori besplatan termin za konzalting i saznaj više o našim programerskim (web stranice i aplikacije) i marketinškim uslugama (SEO, grafički dizajn,  Google oglašavanje i reklamiranje putem društvenih mreža - Facebook, Instagram, Linkedin, Twitter .....)";
        }
      return (
            <ul className="is-center">
                <li><Link to={prefix_string_page}  className="home-menu-item">{home}</Link></li>
                <li><Link to={link_our_services} className="services-menu-item">{our_services}</Link></li>
                <li><Link to={link_about_us} className="about-us-menu-item">{about_us}</Link></li>
               {/* <li><Link to={prefix_string_page + "/" + link_consulting} className="consulting-menu-item" >{consulting}</Link></li>*/}
                <li><Link to={link_portfolio} className="portfolio-menu-item"> {portfolio}</Link></li>
                <li><Link to={link_careers} className="careers-menu-item"> {careers}</Link></li>
                <li><Link to={link_blog} className="blog-menu-item">{blog}</Link></li>
                <li><Link to={link_contact}  className="contact-us" title={contact_tag}>{contact_us}</Link></li>

                <span className="menu-close"></span>
                {Languages(prefix_string_page)}
                <LanguagesMob/>
            </ul>
      )
    }
  }

//DEFAULT MAIN NAVIGATION//
export default Navigation;