import React from 'react'
import Logo from './logo'
import ScrollToTop from 'react-scroll-up'
import Navigation from './navigation'
import Link from 'gatsby-link'

var prefix = "";
function Languages(prefix_string_page){
    if(prefix_string_page === "bs"){
      prefix = "bs";
    }
    return prefix;
}

const Header = ({ prefix_string_page }) => (
    
  <React.Fragment>
    <section id="s_header" className="section is-fixed is-full-width is-padding-0">
        <ScrollToTop showUnder={10}
                style={{
                    zIndex: 99,
                    top:0,
                    left:0,
                    height:'100px',
                    background: 'transparent',
                    cursor: 'default'
                }}>
            <div className="header-back"></div>
        </ScrollToTop>
        <div className="container main-header is-flex">
            <div className="logo is-left">
                <Link to={Languages(prefix_string_page)}><Logo  prefix_string_page = {prefix_string_page}/></Link>
            </div>
            <span className="menu-bar" tabIndex="1"></span>
            <div className="navbar is-right">
                <Navigation prefix_string_page = {prefix_string_page}/>
            </div>
        </div>
    </section>


{/*MOBILE HEADER*/}
  <ScrollToTop showUnder={10}
            style={{
                zIndex: 999,
                top:0,
                left:0,
                height:'100px',
                background: 'transparent',
                cursor: 'default'
            }}>
        <div className="header-back"></div>
    </ScrollToTop>
 
    <div className="container main-header is-flex header-mobile-main">
        <div className="logo is-left">
            <Link to={prefix + "/"}><Logo  prefix_string_page = {prefix_string_page}/></Link>
        </div>    
        <span className="menu-bar" tabIndex="1"></span>
        <div className="navbar is-right">
            <Navigation prefix_string_page = {prefix_string_page}/>
        </div>
    </div>
  </React.Fragment>
)

//DEFAULT MAIN HEADER//
export default Header
